@import "~modern-normalize/modern-normalize.css";
@import "~@cds/core/global.css";
@import "~@cds/core/styles/theme.dark.css";
@import "~@cds/city/css/bundles/default.css";
svg.icon {
  /* Fix positioning */
  position: relative;
  bottom: -0.125em;
}

[slot~=modal-header] {
  color: var(--cds-global-typography-color-400, #383d44);
  font-size: var(--cds-global-typography-font-size-6, 0.55rem);
}

[cds-theme~=dark] .bg-img img, [cds-theme~=dark] .kubeapps-header-subtitle img, [cds-theme~=dark] .section-not-found img {
  filter: invert(1) brightness(100%) saturate(0%); /* stylelint-disable-line scss/no-global-function-names */
}
